
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { takeUntil, finalize, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/core/auth.service';
import { CrudService } from 'src/app/core/crud.service';
import { AuthStorage } from 'src/app/core/auth.storage';
import { ConfirmPasswordValidator } from 'src/app/core/confirm-password.validator';
import { JwtTokenResponse } from 'src/app/core/model/token.response';

@Component({
  selector: 'kt-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

	@Input() data;
	@Input() saveUrl;
	@Input() isSip;
	@Output() summary: EventEmitter<any> = new EventEmitter();
	form: FormGroup;
	title = '';
	loading = false;
	ngxLoading = false;
	private unsubscribe: Subject<any>;
	constructor(public activeModal: NgbActiveModal,  private auth: AuthService, private service: CrudService,
		 private fb: FormBuilder,   private router: Router,
		 private authStorage: AuthStorage, private cdr: ChangeDetectorRef) {
			this.unsubscribe = new Subject();
		  }

	ngOnInit() {
		this.createForm();
	}

	submitForm() {
		// this.summary.emit(this.form.value);
		// this.activeModal.close();
		this.ngxLoading = true;
		const password = this.form.get('password').value;
		const email = this.form.get('email').value;
    this.data.customer.password = password;
    this.data.customer.email = email;
    this.data.customer.dateCreated = null;

		this.service.save(this.data, this.saveUrl).subscribe(
			result => {
				this.ngxLoading = false;
				this.authClient(email, password, result);
			},
			error => {
				this.ngxLoading = false;
			  console.log(error);
			},
			() => {
			  this.ngxLoading = false;
			  this.cdr.markForCheck();
			}
		)

	  }

	createForm() {
		this.form = this.fb.group({
			firstName: [this.data.customer.firstName],
			lastName: [this.data.customer.lastName],
			mobileNumber: [this.data.customer.mobileNumber],
			email: [this.data.customer.email,
			Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
		],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(5),
				Validators.maxLength(100)
			])
			],
			confirmPassword: ['', Validators.compose([
				Validators.required,
				Validators.minLength(5),
				Validators.maxLength(100)
			])
			]

		}, {
			validator: ConfirmPasswordValidator.MatchPassword
		});
  }
  isControlHasError(controlName: string, validationType: string): boolean {
	const control = this.form.controls[controlName];
	if (!control) {
		return false;
	}
	const result = control.hasError(validationType) && (control.dirty || control.touched);
	return result;
}

// authClient(email: string, pwd: string, result: any) {
// 	this.ngxLoading = true;
// 	const authData = {userName: email, password: pwd};
// 	this.auth
// 	.login(authData.userName, authData.password)
// 	.pipe(
// 		tap((jwtTokenResponse: JwtTokenResponse) => {
// 			this.ngxLoading = false;
// 			if (jwtTokenResponse) {
// 				this.authStorage.saveToken( jwtTokenResponse.token);
// 				this.authStorage.saveUser(JSON.stringify(jwtTokenResponse.customer));
// 				this.router.navigate(['/get_covered/members/' + this.isSip + '/' + result.id]);
// 			}
// 			this.activeModal.close();
// 		}),
// 		takeUntil(this.unsubscribe),
// 		finalize(() => {
// 			this.ngxLoading = false;
// 			this.cdr.markForCheck();
// 		})
// 	)
// 	.subscribe();
// }


authClient(email: string, pwd: string, result: any) {
  this.ngxLoading = true;
  const authData = {userName: email, password: pwd};
  this.auth
  .login(authData.userName, authData.password)
  .pipe(
    tap((jwtTokenResponse: JwtTokenResponse) => {
      this.ngxLoading = false;
      if (jwtTokenResponse) {
        this.authStorage.saveToken( jwtTokenResponse.token);
        this.authStorage.saveUser(JSON.stringify(jwtTokenResponse.customer));
        this.router.navigate(['/get_covered/members/' + this.isSip + '/' + result.id]);
      } else {
        // this.toastService.error('Error Encountered !', 'Error');
        // this.viewMsg = true;
        this.router.navigateByUrl('/auth/login');
      }
      this.activeModal.close();
    }),
    takeUntil(this.unsubscribe),
    finalize(() => {
      this.ngxLoading = false;
      // this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
      this.cdr.markForCheck();
    })
  )
  .subscribe();
}


/*
    * On destroy
    */
   ngOnDestroy(): void {
	this.unsubscribe.next();
	this.unsubscribe.complete();
	this.loading = false;
	this.ngxLoading = false;
}

}
