<mat-toolbar class="header">

  <div class="header__title">
    <img class="logo-img" src="./assets/logos/nfs-logo.png"  alt="logo">
    <button style="margin-left: 20px;" class="header__title-button" color="primary" mat-icon-button (click)="openMenu()">
      <mat-icon *ngIf="!isMenuOpened">menu</mat-icon>
      <mat-icon *ngIf="isMenuOpened">menu</mat-icon>
    </button>
    <!-- <h6 class="header__title-text">Angular Material Admin</h6> -->

    <div class="small-screen">
      <button mat-flat-button (click)="navigate('/dashboard')">Dashboard</button>
    </div>
    <div class="small-screen">
      <button mat-flat-button (click)="navigate('/policy/other')">My Policy</button>
    </div>
    <div class="small-screen">
      <button mat-flat-button [matMenuTriggerFor]="payments">Payments</button>
      <mat-menu #payments="matMenu">
        <button mat-menu-item (click)="navigate('/payments/new')">Make Payment</button>
        <button mat-menu-item (click)="navigate('/payments/history')">Payment History</button>
      </mat-menu>
    </div>
    <div class="small-screen">
      <button mat-flat-button [matMenuTriggerFor]="getcover">Get Cover</button>
      <mat-menu #getcover="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="individualpolicies" (click)="navigate('/payments/new')">Individual Policies</button>
        <button mat-menu-item (click)="navigate('/get_covered/recent_quotes')">Recent Quotes</button>
        <button mat-menu-item (click)="navigate('/get_covered/application')">Policy Application</button>
      </mat-menu>
      <mat-menu #individualpolicies="matMenu">
        <button mat-menu-item  (click)="navigate('/get_covered/local/sp/SIPSA')">SIP SA</button>
        <!-- <button mat-menu-item (click)="navigate('/get_covered/download_forms')">Download form & apply</button> -->
      </mat-menu>
    </div>

    <div class="small-screen">
      <button mat-flat-button (click)="navigate('/profile')">My Profile</button>
    </div>

  </div>

  <div class="header-toolbar">
    <!-- <app-search></app-search>
    <app-notifications></app-notifications>
    <app-email [emails]="emails$ | async"></app-email> -->
    <app-user [user]="user" (signOut)="signOut()"></app-user>
  </div>
</mat-toolbar>
