
  <mat-card>
    <div class="row">
      <div class="col-xl-8">


        <!--begin: Form Wizard Form-->
        <form [formGroup]="form">
          <mat-horizontal-stepper linear labelPosition="bottom" #stepper>
            <mat-step formGroupName="mainMember" [stepControl]="mainMember" >
              <ng-template matStepLabel>Main Member Details</ng-template>
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <label>Firstname</label>
                    <input type="text" class="form-control" formControlName="firstName" placeholder="Firstname"
                      required>
                    <span *ngIf="mainMember.get('firstName').touched && mainMember.get('firstName').errors?.required"
                      class="help-block text-danger">
                      <p>Firstname field is required.</p>
                    </span>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <label>Lastname</label>
                    <input type="text" class="form-control" formControlName="lastName" placeholder="Lastname" required>
                    <span *ngIf="mainMember.get('lastName').touched && mainMember.get('lastName').errors?.required"
                      class="help-block text-danger">
                      <p>Lastname field is required.</p>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <label>Mobile Number</label>
                    <input type="text" class="form-control" formControlName="mobileNumber" placeholder="Mobile Number"
                      required>
                    <span
                      *ngIf="mainMember.get('mobileNumber').touched && mainMember.get('mobileNumber').errors?.required"
                      class="help-block text-danger">
                      <p>Mobile number field is required.</p>
                    </span>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="email" class="form-control" formControlName="email" placeholder="Email" required>
                    <span *ngIf="mainMember.get('email').touched && mainMember.get('email').errors?.required"
                      class="help-block text-danger">
                      <p>Email field is required.</p>
                    </span>
                    <span *ngIf="mainMember.get('email').touched && mainMember.get('email').errors?.email"
                      class="help-block text-danger">
                      <p>Incorrect Email.</p>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <label>Gender</label>
                    <select formControlName="gender"  class="form-control" required="true">
                      <option value="MALE">MALE</option>
                      <option value="FEMALE">FEMALE</option>
                    </select>
                    <span *ngIf="mainMember.get('gender').touched && mainMember.get('gender').errors?.required"
                      class="help-block text-danger">
                      <p>Gender is required.</p>
                    </span>
                  </div>
                </div>
                <div class="col-xl-6">

                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <label>Street</label>
                    <input type="text" class="form-control" formControlName="street" placeholder="Street">

                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <label>City</label>
                    <input type="text" class="form-control" formControlName="city" placeholder="City">

                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <label>Province/State</label>
                    <input type="text" class="form-control" formControlName="province" placeholder="State">

                  </div>
                </div>
                <!-- <div class="col-xl-6">
                  <div class="form-group">
                    <label>Country</label>
                    <input type="text" class="form-control" formControlName="country" placeholder="Country" readonly>
                  </div>
                </div> -->
                <div class="col-xl-6">
                  <div class="form-group">
                    <label>Country</label>
                    <select formControlName="country"  class="form-control" required="true">
                      <option [ngValue]="undefined" selected="true">--Select Country--</option>
                      <option value="ZIMBABWE">ZIMBABWE</option>
                      <option value="SOUTH AFRICA">SOUTH AFRICA</option>
                    </select>
                    <span *ngIf="mainMember.get('country').touched && mainMember.get('country').errors?.required"
                      class="help-block text-danger">
                      <p>Country is required.</p>
                    </span>
                  </div>
                </div>
              </div>
              <div class="step-btns">
                <button mat-raised-button color="primary" matStepperNext>Next</button>
              </div>
            </mat-step>
            <mat-step [completed]="isProductSelected">
              <ng-template matStepLabel>Select Product</ng-template>
              <h5>Please choose plan type and bus option</h5>
              <div class="row">

                <div class="col-xl-6">
                  <div class="form-group">
                    <label>CHOOSE PLAN TYPE:</label>
                    <select [(ngModel)]="planType" [ngModelOptions]="{standalone: true}"
                      (ngModelChange)="onChangePlanTypes('MAIN_MEMBER', false)" class="form-control">
                      <option [ngValue]="undefined">--Select Plan--</option>
                      <option *ngFor="let item of planTypes" [ngValue]="item">{{item.name}}</option>
                    </select>
                    <span *ngIf="planType === undefined" class="help-block text-danger">
                      <p>Plan Type is required.</p>
                    </span>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <label>PRODUCT TYPE</label>
                    <select [(ngModel)]="productType" [ngModelOptions]="{standalone: true}"
                      (ngModelChange)="onChangePlanTypes('MAIN_MEMBER', false)" class="form-control">
                      <option [ngValue]="undefined">--Select Product Type--</option>
                      <option value="FAMILY">FAMILY</option>
                      <option value="INDIVIDUAL">INDIVIDUAL</option>
                    </select>
                    <span *ngIf="productType === undefined" class="help-block text-danger">
                      <p>Please choose from the option.</p>
                    </span>
                  </div>
                </div>


              </div>
              <div class="step-btns">
                <button mat-button matStepperPrevious>Back</button>&nbsp;
                <button mat-raised-button color="primary" matStepperNext >Next</button>
              </div>
            </mat-step>
            <mat-step>
              <ng-template matStepLabel>Select Benefits</ng-template>
              <div class="row">
                <div class="col-xl-6" *ngFor="let item of supplimentaryBenefits">
                  <mat-checkbox (change)="onChangeMainBenefits(item, $event.checked)"
                    disabled="{{disableMMBenefits && mainMemberBenefit.supplimentaryID !== item.supplimentaryID}}">
                    {{item.name}}</mat-checkbox>
                  <!-- <span>&nbsp;<button mat-icon-button color="primary" placement="right" [ngbPopover]="item.desc"
                      popoverTitle="Sahwira Lite">

                    </button>
                  </span> -->
                </div>

              </div>
              <span class="help-block">
                <p>Choose benefits.</p>
              </span>
              <div class="step-btns">
                <button mat-button matStepperPrevious>Back</button>&nbsp;
                <button mat-raised-button color="primary" matStepperNext>Next</button>
              </div>
            </mat-step>
            <mat-step>
              <ng-template matStepLabel>DEPENDANTS</ng-template>
              <div class="kt-wizard-v1__form">


                <div class="mat-h4 text-center">DEPENDANTS</div>
                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label>Plan Type Of Dependents</label>
                      <select [(ngModel)]="planTypeDependents" [disabled]="readonlyForProduct"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="planTypeDependentsChange()"
                        class="form-control">
                        <option [ngValue]="undefined">--Select Plan--</option>
                        <option *ngFor="let item of planTypesForDependents" [ngValue]="item">{{item.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label>Number Of SA Dependents(excluding children)</label>
                      <input type="number" class="form-control" min="1" [readonly]="readonlyForProduct"
                        [(ngModel)]="numOfSaDependents" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="numOfDependentsChange(false)" placeholder="Number Of SA Dependents">
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label>Number Of ZIM Dependents(excluding children)</label>
                      <input type="number" class="form-control" min="1" [readonly]="readonlyForProduct"
                        [(ngModel)]="numOfZimDependents" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="numOfDependentsChange(true)" placeholder="Number Of ZIM Dependents">
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <!-- <div class="form-group">
                      <label>CHOOSE BUS OPTION</label>
                      <select [(ngModel)]="dependentsSubPlanType" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="planTypeDependentsChange()" class="form-control"
                        disabled="{{subgroup === 'SIXPACK'}}">
                        <option [ngValue]="undefined">--Select Bus Option--</option>
                        <option value="PLUS">With Bus</option>
                        <option value="STANDARD">With No Bus</option>
                      </select>
                      <span *ngIf="dependentsSubPlanType === undefined" class="help-block text-danger">
                        <p>Please choose.</p>
                      </span>
                    </div> -->
                  </div>




                </div>
                <table style="width: 100%;" class="table table-sm table-hover table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Dependent</th>
                      <th scope="col">Plan Type</th>
                      <th scope="col">Cover</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="subgroup === 'SIXPACK'">
                      <th>SPOUSE (1)</th>
                      <td>{{planType?.name}}</td>
                      <td></td>
                    </tr>
                    <!-- <tr *ngFor="let number of dependentsCount"> -->
                    <tr *ngIf="numOfSaDependents>0">
                      <th> SA Dependant(s) X {{numOfSaDependents}}</th>
                      <td>{{planTypeDependents?.name}} </td>
                      <td>
                        <ul>

<!--
                          <li class="blinking" [ngbPopover]="popContentExtendedMember" triggers="mouseenter:mouseleave"
                            popoverTitle="{{extendedMemberProduct?.benefits?.casket}}">
                            {{extendedMemberProduct?.benefits?.casket}}</li>
                          <li *ngIf="dependentsSubPlanType === 'PLUS'">Bus</li>
                          <li>{{extendedMemberProduct?.benefits?.grocery}}</li>
                          <li>Body removal, Wash and dress, Hearse</li> -->
                        </ul>
                      </td>
                      <!-- <td><strong>ZAR {{mainMemberPremium | number:'.2-2' }}</strong></td> -->
                    </tr>
                    <tr *ngIf="numOfZimDependents>0">
                      <th> Zim Dependant(s) X {{numOfZimDependents}}</th>
                      <td>{{planTypeDependents?.name}} </td>
                      <td>
                        <ul>

<!--
                          <li class="blinking" [ngbPopover]="popContentExtendedMember" triggers="mouseenter:mouseleave"
                            popoverTitle="{{extendedMemberProduct?.benefits?.casket}}">
                            {{extendedMemberProduct?.benefits?.casket}}</li>
                          <li *ngIf="dependentsSubPlanType === 'PLUS'">Bus</li>
                          <li>{{extendedMemberProduct?.benefits?.grocery}}</li>
                          <li>Body removal, Wash and dress, Hearse</li> -->
                        </ul>
                      </td>
                      <!-- <td><strong>ZAR {{mainMemberPremium | number:'.2-2' }}</strong></td> -->
                    </tr>
                  </tbody>
                </table>
                <h6 *ngIf="(numOfSaDependents + numOfZimDependents) > 0" class="text-center">CHOOSE BENEFITS FOR DEPENDENTS:</h6>
                <div *ngIf="(numOfSaDependents + numOfZimDependents) > 0" class="row">
                  <div class="col-xl-6" *ngFor="let item of mainMemberBenefits">
                    <mat-checkbox (change)="onChangeExtendedBenefits(item, $event.checked)">{{item.name}}</mat-checkbox>
                    <!-- <span>&nbsp;<button mat-icon-button color="primary" placement="right" [ngbPopover]="item.desc"
                        popoverTitle="Sahwira Lite">

                      </button>
                    </span> -->
                  </div>
                </div>
              </div>
              <div class="step-btns">
                <button mat-button matStepperPrevious>Back</button>&nbsp;
                <button mat-raised-button color="primary" matStepperNext>Next</button>
              </div>
            </mat-step>
            <mat-step>
              <ng-template matStepLabel>Your Quotation</ng-template>


              <div id="print-section">
                <div class="mat-h4 text-center">SIP SA</div>
                <div class="mat h5 text-center"> TOTAL - ZAR {{totalPremium | number:'.2-2' }} per/month</div>
                <hr>
                <h6 class="text-center">MAIN MEMBER</h6>
                <table style="width: 100%;" class="table table-hover table-striped">

                  <tbody>
                    <tr *ngIf="mainMemberProduct !== undefined">
                      <th>Casket</th>
                      <td></td>
                      <td class="blinking" [ngbPopover]="popContentMainMember" triggers="mouseenter:mouseleave"
                        popoverTitle="{{mainMemberProduct?.benefits?.casket}}">{{mainMemberProduct?.benefits?.casket}}
                      </td>
                    </tr>
                    <tr *ngIf="mainMemberProduct !== undefined">
                      <th>Grocery</th>
                      <td></td>
                      <td>{{mainMemberProduct?.benefits?.grocery}}</td>
                    </tr>
                    <tr>
                      <th>Static Benefits </th>
                      <td></td>
                      <td> Body removal, Wash and dress, Hearse </td>
                    </tr>
                    <tr>
                      <th style="width: 35%;">{{planType?.name}}</th>
                      <td style="width: 35%;"> </td>
                      <td><strong>ZAR {{mainMemberPremium | number:'.2-2' }}</strong></td>
                    </tr>
                  </tbody>
                </table>
                <hr *ngIf="mainMemberBenefits.length > 0">
                <div class="mat-h5 text-center" *ngIf="mainMemberBenefits.length > 0">MAIN MEMBER BENEFITS</div>
                <table style="width: 100%;" *ngIf="mainMemberBenefits.length > 0"
                  class="table table-hover table-striped">

                  <tbody>
                    <tr *ngFor="let item of mainMemberBenefits">
                      <td style="width: 70%;">{{item.name}}</td>

                      <td>ZAR {{item.premium}}</td>
                    </tr>
                    <tr>
                      <td style="width: 70%;"><strong>TOTAL</strong></td>

                      <td><strong>ZAR {{mainMemberBenefitsAmt | number:'.2-2' }}</strong></td>
                    </tr>
                  </tbody>
                </table>
                <hr>

                <div class="mat-h5 text-center"> DEPENDENTS</div>

                <table style="width: 100%;" class="table table-hover  table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Plan Type</th>
                      <th scope="col">No. Of Dependents</th>
                      <th scope="col">Premium</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{planTypeDependents?.name}}</td>
                      <td>{{numOfSaDependents + numOfZimDependents}} </td>
                      <td>ZAR {{extendedMemberSaPremium + extendedMemberZimPremium| number:'.2-2'}}</td>
                    </tr>


                  </tbody>
                </table>


                <div class="mat-h5 text-center" *ngIf="extendedMemberBenefits.length > 0">Extended Benefits - ZAR
                  {{extendedMemberBenefitsAmt | number:'.2-2' }}</div>
                <table *ngIf="extendedMemberBenefits.length > 0" style="width: 100%;"
                  class="table table-hover table-striped">
                  <tbody>
                    <tr *ngFor="let item of extendedMemberBenefits">
                      <td style="width: 70%;">{{item.name}}</td>

                      <td>ZAR {{item.premium}} * {{(numOfSaDependents + numOfZimDependents)}} </td>
                    </tr>
                    <tr>
                      <td style="width: 70%;"><strong>TOTAL</strong></td>

                      <td><strong>ZAR {{extendedMemberBenefitsAmt | number:'.2-2' }}</strong></td>
                    </tr>
                  </tbody>
                </table>
                <table style="width: 100%;" class="table table-hover table-striped">

                  <tbody>
                    <!-- <tr *ngIf="extendedMemberProduct !== undefined">
                      <th>Casket</th>
                      <td></td>
                      <td class="blinking" [ngbPopover]="popContentExtendedMember" triggers="mouseenter:mouseleave"
                        popoverTitle="{{extendedMemberProduct?.benefits?.casket}}">
                        {{extendedMemberProduct?.benefits?.casket}}</td>
                    </tr> -->
                    <!-- <tr *ngIf="extendedMemberProduct !== undefined">
                      <th>Grocery</th>
                      <td></td>
                      <td>{{extendedMemberProduct?.benefits?.grocery}}</td>
                    </tr> -->
                    <tr>
                      <th>Static Benefits </th>
                      <td></td>
                      <td> Body removal, Wash and dress, Hearse </td>
                    </tr>

                  </tbody>
                </table>

                <table style="width: 100%;" class="table table-hover table-bordered table-striped">
                  <tr>

                    <td style="width: 70%;">
                      <h5><strong>MAIN MEMBER PREMIUM</strong></h5>
                    </td>
                    <td>
                      <h5><strong>ZAR {{mainMemberPremium + mainMemberBenefitsAmt | number:'.2-2' }} per/month</strong>
                      </h5>
                    </td>

                  </tr>
                  <tr>

                    <td style="width: 70%;">
                      <h5><strong>EXTENDED MEMBER PREMIUM</strong></h5>
                    </td>
                    <td>
                      <h5><strong>ZAR {{extendedMemberSaPremium + extendedMemberZimPremium +  extendedMemberBenefitsAmt | number:'.2-2' }}
                          per/month</strong></h5>
                    </td>

                  </tr>
                  <tr>

                    <td style="width: 70%;">
                      <h5><strong>TOTAL PREMIUM</strong></h5>
                    </td>
                    <td>
                      <h5><strong><u>ZAR {{totalPremium | number:'.2-2' }} per/month</u></strong></h5>
                    </td>

                  </tr>
                </table>

              </div>
              <div class="rk-space-items-between">
                <p>View quote and proceed to policy creation. Your policy will be created instantly.</p>
                <button [useExistingCss]="true" mat-stroked-button color="primary"
                printSectionId="print-section" ngxPrint>
                <mat-icon>print</mat-icon>Print
                </button>
              <!-- <button (click)="downloadForm()" mat-raised-button color="danger">
                <mat-icon>get_app</mat-icon>Download Form
              </button> -->
              </div>
              <div class="step-btns">
                <button mat-button matStepperPrevious>Back</button>&nbsp;
                <button mat-raised-button color="primary" (click)="onSubmit()">Apply Now</button>
              </div>
            </mat-step>
          </mat-horizontal-stepper>
          <ng-template #popContentMainMember>
            <img *ngIf="planType?.name === 'DIAMOND'" width="200px" height="auto"
              src="./assets/media/caskets/diamond.jpeg">
            <img *ngIf="planType?.name === 'PLATINUM'" width="200px" height="auto"
              src="./assets/media/caskets/platinum.jpeg">
            <img *ngIf="planType?.name === 'GOLD'" width="200px" height="auto" src="./assets/media/caskets/gold.jpeg">
            <img *ngIf="planType?.name === 'SILVER'" width="200px" height="auto"
              src="./assets/media/caskets/silver.jpg">
          </ng-template>
          <ng-template #popContentExtendedMember>
            <img *ngIf="planTypeDependents?.name === 'DIAMOND'" width="200px" height="auto"
              src="./assets/media/caskets/diamond.jpeg">
            <img *ngIf="planTypeDependents?.name === 'PLATINUM'" width="200px" height="auto"
              src="./assets/media/caskets/platinum.jpeg">
            <img *ngIf="planTypeDependents?.name === 'GOLD'" width="200px" height="auto"
              src="./assets/media/caskets/gold.jpeg">
            <img *ngIf="planType?.name === 'SILVER'" width="200px" height="auto"
              src="./assets/media/caskets/silver.jpg">
          </ng-template>
        </form>








      </div>
      <div class="col-xl-4">

        <div class="card">
          <div class="card-body">
            <h4 class="text-center">SIP SA</h4>
            <h5 class="text-center">ZAR {{totalPremium | number:'.2-2' }} per/month</h5>
            <hr>
            <h6 class="text-center">MAIN MEMBER</h6>

            <table style="width: 100%;" class="table table-hover  table-striped">
              <tbody>
                <tr *ngIf="mainMemberProduct !== undefined">
                  <th>Casket</th>

                  <td class="blinking" [ngbPopover]="popContentMainMember" triggers="mouseenter:mouseleave"
                    popoverTitle="{{mainMemberProduct?.benefits?.casket}}">{{mainMemberProduct?.benefits?.casket}}</td>
                </tr>
                <tr *ngIf="mainMemberProduct !== undefined">
                  <th>Grocery</th>

                  <td>{{mainMemberProduct?.benefits?.grocery}}</td>
                </tr>
                <tr>
                  <th>Static Benefits </th>

                  <td> Body removal, Wash and dress, Hearse </td>
                </tr>
                <tr>
                  <th>Plan </th>

                  <td> {{planType?.name}} </td>
                </tr>

                <tr>
                  <th>Premium</th>

                  <th> ZAR {{mainMemberPremium}} </th>
                </tr>

              </tbody>
            </table>
            <hr>
            <h6 *ngIf="mainMemberBenefits.length > 0" class="text-center">MAIN MEMBER BENEFITS</h6>
            <table *ngIf="mainMemberBenefits.length > 0" style="width: 100%;" class="table table-sm">
              <tbody>

                <tr *ngFor="let item of mainMemberBenefits">
                  <td>{{item.name}}</td>
                  <td>ZAR {{item.premium | number:'.2-2' }} </td>
                </tr>
                <tr>
                  <th>Total</th>
                  <th>ZAR {{mainMemberBenefitsAmt}}</th>
                </tr>
              </tbody>
            </table>
            <hr>



            <h6 class="text-center"> DEPENDENTS</h6>
            <table style="width: 100%;" class="table table-hover  table-striped">
              <thead>
                <tr>
                  <th scope="col">Plan Type</th>
                  <th scope="col">No. Of Dependents</th>
                  <th scope="col">Premium</th>

                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{planTypeDependents?.name}}</td>
                  <td>{{numOfSaDependents + numOfZimDependents}} </td>
                  <th>ZAR {{extendedMemberSaPremium + extendedMemberZimPremium | number:'.2-2'}}</th>
                </tr>


              </tbody>
            </table>




            <hr>
            <h6 *ngIf="extendedMemberBenefits.length > 0" class="text-center">Extended Benefits - ZAR
              {{extendedMemberBenefitsAmt | number:'.2-2' }}</h6>
            <table *ngIf="extendedMemberBenefits.length > 0" style="width: 100%;" class="table table-sm">
              <tbody>
                <tr *ngFor="let item of extendedMemberBenefits">
                  <td>{{item.name}}</td>
                  <td>ZAR {{item.premium | number:'.2-2' }} * {{numOfSaDependents + numOfZimDependents}} </td>
                </tr>
                <tr>
                  <th>Total</th>
                  <th>ZAR {{extendedMemberBenefitsAmt}}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </mat-card>
  <app-footer></app-footer>

