<app-layout>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '5px' }"></ngx-loading>
  <div class="mat-h3 dash-title">Welcome To SA Nyaradzo Self Service</div>

  <div class="policy-info-dropdown">

    <div ngbDropdown class="d-inline-block">
      <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle> {{activePolicy | uppercase}}
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button *ngIf="customer.policyNumber !== null" ngbDropdownItem
          (click)="switchPolicies({policyNumber: customer?.policyNumber, idNumber: customer?.idNumber})">{{customer?.policyNumber
          | uppercase}} [{{customer?.idNumber | uppercase}}]</button>
        <button *ngFor="let item of customer!.policyAccountList" ngbDropdownItem
          (click)="switchPolicies(item)">{{item?.policyNumber | uppercase}} [{{item?.idNumber | uppercase}}]</button>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-md-6">
      <div
        class="{{policyBalance?.Status === 1 ? 'rk-policy-quick-infor-active': policyBalance?.Status === 0 ? 'rk-policy-quick-infor-active': 'rk-policy-quick-infor-archived'}} d-flex justify-content-between">
        <div class="mat-h3">Policy Status</div>&nbsp;
        <div *ngIf="policyBalance?.Status === 3" class="mat-h1">Suspended</div>
        <div *ngIf="policyBalance?.Status === 2" class="mat-h1">Lasped</div>
        <div *ngIf="policyBalance?.Status === 1" class="mat-h1">Active</div>
        <div *ngIf="policyBalance?.Status === 0" class="mat-h1">InActive</div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="rk-policy-quick-infor-active d-flex justify-content-between">
        <div class="mat-h3">Balance</div>&nbsp;
        <div class="mat-h1">{{policyBalance?.Balance}}<sup class="sup-style">ZAR</sup></div>
      </div>
    </div>
  </div>


  <!-- <iframe src="https://www.topup.co.zw/billpay/widget/nyaradzo-funeral-life-assurance?iframe=true" style="width: 100%;padding: 15px;border: 3px solid lightgrey;border-radius: 6px;height: 500px;"></iframe> -->
  <ngb-carousel style="margin: 37px;" [showNavigationArrows]="false" [showNavigationIndicators]="true">
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img class="courasel-img" src="./assets/media/images/Slider-1-1.jpg" alt="Random slide">
      </div>
      <div class="carousel-caption">
        <div class="mat-h2">TODAY'S PLAN,</div>
        <div class="mat-h4">TOMMOROW'S PEACE OF MIND</div>
        <div class="rk-gt-btn">
          <a role="button" class="rk-get-quote-btn" (click)="navigate('/get_covered/local/sp/SIPSA')">GET QUOTE</a>
        </div>

      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img class="courasel-img" src="./assets/media/images/Slider-2-2.jpg" alt="Random slide">
      </div>
      <div class="carousel-caption">
        <div class="mat-h2">TODAY'S PLAN,</div>
        <div class="mat-h4">TOMMOROW'S PEACE OF MIND</div>
        <div class="rk-gt-btn">
          <a role="button" class="rk-get-quote-btn" (click)="navigate('/get_covered/local/sp/SIPSA')">GET QUOTE</a>
        </div>

      </div>
    </ng-template>
  </ngb-carousel>

  <div class="history-section">
    <div class="mat-h4 payments-title">Payments</div>
    <app-payment-history></app-payment-history>
  </div>
  <app-footer></app-footer>
</app-layout>
