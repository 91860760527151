export class LocalQuoteSummary {
	customerId?: string;
	customer: any;
	mainMemberProduct?: any;
	extendedMemberProduct?: any;
  extendedMemberMarkedForZimProduct?: any;
	mainMemberBenefits: any[];
	extendedMemberBenefits: any[];
	numOfDependents: number;
  numOfZimMarkedDependents: number;
  subGroup: any;
  subGroupId: number;

  r45636250: number; //totalPremium
  b45664925	: number; //mainMemberBenefitsAmt
  c4566446: number; // extendedMemberBenefitsAmt
  t45663625: number; //extendedMemberPremium

	subGroupName: string;
  nyaradzoFranchise: string;
}
