import { SharedModule } from './../shared.module';
import { PasswordComponent } from './password/password.component';
import { NgModule } from '@angular/core';


import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxLoadingModule } from 'ngx-loading';
import {  NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChooseSpecificCountryComponent } from './choose-specific-country/choose-specific-country.component';
import {NgxPrintModule} from 'ngx-print';
import { AddMembersComponent } from './add-members/add-members.component';
import { AddMemberDialogComponent } from './add-member-dialog/add-member-dialog.component';
import { LocalQuotesComponent } from './local-quotes/local-quotes.component';
import { GetQuoteComponent } from './get-quote/get-quote.component';
import { DownloadFormDialogComponent } from './download-form-dialog/download-form-dialog.component';
import { ViewPolicyApplicationComponent } from './view-policy-application/view-policy-application.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatStepperModule} from '@angular/material/stepper';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';

@NgModule({
  imports: [
    CommonModule,
	MatFormFieldModule,
	FormsModule,
	NgxLoadingModule,
	NgbModule,
	NgxPrintModule,
	MatListModule,
	ReactiveFormsModule,
  MatFormFieldModule,
  MatStepperModule,
	MatInputModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatTableModule,
    MatButtonModule, MatTooltipModule, MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatDialogModule,
    MatSelectModule,
    MatCardModule,
    SharedModule
  ],
  exports: [  ChooseSpecificCountryComponent, AddMembersComponent, LocalQuotesComponent, GetQuoteComponent,
     PasswordComponent, DownloadFormDialogComponent, ViewPolicyApplicationComponent, PaymentHistoryComponent],
  declarations: [  ChooseSpecificCountryComponent, AddMembersComponent, AddMemberDialogComponent, PaymentHistoryComponent,
     LocalQuotesComponent, GetQuoteComponent, PasswordComponent, DownloadFormDialogComponent, ViewPolicyApplicationComponent, PaymentHistoryComponent],
  entryComponents:[AddMemberDialogComponent, PasswordComponent, DownloadFormDialogComponent],
  providers: [DatePipe]
})
export class SharedComponentsModule {}
