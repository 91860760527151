import { JwtTokenResponse } from './../../../../core/model/token.response';
import { AuthService } from './../../../../core/auth.service';
import { ConfirmPasswordValidator } from './../../../../core/confirm-password.validator';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthStorage } from 'src/app/core/auth.storage';
import { CrudService } from 'src/app/core/crud.service';
import { tap, takeUntil, finalize } from 'rxjs/operators';
import { MessageAlert } from 'src/app/core/model/message.alert';

@Component({
  selector: 'app-register-client',
  templateUrl: './register-client.component.html',
  styleUrls: ['./register-client.component.scss']
})
export class RegisterClientComponent implements OnInit {

  registerForm: FormGroup;
	loading = false;
	errors: any = [];
	policy: any;
	isPolicyExist = false;
	accountExist = false;
	policyNumberReadonly = false;
	idNumberReadonly = false;
	//ngxLoading = false;
	idNumberMismatch = false;
  clientInputIdNumber = null;
  messageAlert: MessageAlert = {viewMsg: false, msg: '', type: 'info'};
  passwordPattern = '(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}'
  constructor(
		private router: Router,
		private auth: AuthService,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private service: CrudService,
		private authStorage: AuthStorage) { }

  ngOnInit() {
    this.initRegisterForm();
  }


 initRegisterForm() {
   this.registerForm = this.fb.group({
     policyNumber: ['', Validators.compose([
       Validators.required,
       Validators.minLength(3),
       Validators.maxLength(100)
     ])
     ],
     idNumber: ['', Validators.compose([
       Validators.required,
       Validators.minLength(3),
       Validators.maxLength(100)
     ]),
     ],
     id: [null],
     firstName: [''],
     lastName: [''],
     registrationApp: ['CLIENT_SERVICE_PORTAL'],
     email: ['xxxx@xxxx.com',
     Validators.compose([
       Validators.required,
       Validators.email,
       Validators.minLength(3),
       Validators.maxLength(100)
     ]),
   ],
     mobileNumber: ['0777000000',
     Validators.compose([
       Validators.required,
       Validators.minLength(7),
       Validators.maxLength(30)
     ]),
   ],
     password: ['Pass1234', Validators.compose([
       Validators.required,
       Validators.minLength(6),
       Validators.maxLength(100)
     ])
     ],
     confirmPassword: ['Pass1234', Validators.compose([
       Validators.required,
       Validators.minLength(6),
       Validators.maxLength(100)
     ])
     ],
     physicalAddress: [''],
     postalAddress: [''],
     idNumberMismatch: [false],
     clientInputIdNumber: [null],
     gender: [''],
     maritalStatus: [''],
     title: [''],
     dateOfBirth: [''],
     passportNumber: [''],
     nyaradzoFranchise: ['SA'],
     roles: [['ROLE_BASIC']],

   }, {
     validator: ConfirmPasswordValidator.MatchPassword
   });
 }

 /**
  * Form Submit
  */
 submitForm(validatePolicy: boolean) {
   const controls = this.registerForm.controls;
   if (this.registerForm.invalid) {
     Object.keys(controls).forEach(controlName =>
       controls[controlName].markAsTouched()

     );
     return;
   }
   this.loading = true;
   if (validatePolicy) {
       this.validatePolicy();
     } else {
                this.saveClientDetails();
     }
 }
 validatePolicy() {
   const policyNumber = this.registerForm.get('policyNumber').value;
   const idNumber = this.registerForm.get('idNumber').value;
  this.messageAlert = {viewMsg: false, msg: '', type: 'info'};
   this.service.getAny('/easipol/policy/get-by-franchise-and-policy-number?nyaradzoFranchise=SA&policyNumber=' + policyNumber).subscribe(
     result => {
         this.loading = false;
         if ( result !== null) {
           this.policy = result;
          // console.log(this.policy);
           const idAndPassportList:string[] = [this.policy?.MainMember?.ID_Number.toUpperCase(), this.policy?.MainMember?.Passport_Number];
          // console.log(idAndPassportList);

           if(this.policy.MainMember.ID_Number.toUpperCase() !== idNumber.toUpperCase()){
             this.idNumberMismatch = true;
             this.clientInputIdNumber = idNumber.toUpperCase();

           }
           this.isPolicyExist = true;
           this.setPolicyDetails(this.policy);
           this.messageAlert = {viewMsg: true, msg: 'Policy details found. Enter password to proceed.', type: 'info'};
         } else {
         this.messageAlert = {viewMsg: true, msg: 'Policy Details Not Found !!!', type: 'danger'};

         }

       },
       error => {
         this.loading = false;
         this.isPolicyExist = false;
       },
       () => {
         this.loading = false;
         this.cdr.markForCheck();
       }
     );
 }

 saveClientDetails() {
      this.service.save(this.registerForm.value, '/customer/save').subscribe(result => {
      this.loading = false;
      if (result.duplicate) {
     this.accountExist = true;
     this.messageAlert = {viewMsg: true, msg: 'Account already exists!', type: 'danger'};
      } else if (result) {
     this.messageAlert = {viewMsg: true, msg: 'Account created successfully!. Logging you in...', type: 'info'};
     this.authClient(result.item.email, this.registerForm.get('password').value);
      }
    },
    error => {
   this.loading = false;
    this.messageAlert = {viewMsg: true, msg: 'Error Encountered when creating your account!', type: 'danger'};
    },
    () => {
   this.loading = false;
   this.cdr.markForCheck();
 }
    );
 }
 authClient(email: string, pwd: string) {
   this.loading = true;
   const authData = {userName: email, password: pwd};
   this.auth
   .login(authData.userName, authData.password)
   .pipe(
     tap((jwtTokenResponse: JwtTokenResponse) => {
       this.loading = false;
       if (jwtTokenResponse) {
         this.authStorage.saveToken( jwtTokenResponse.token);
         this.authStorage.saveUser(JSON.stringify(jwtTokenResponse.customer));
         this.router.navigate(['/dashboard']);
       } else {
        this.messageAlert = {viewMsg: true, msg: 'Error Encountered !', type: 'danger'};
         this.router.navigateByUrl('/auth/login');
       }
     }),
     finalize(() => {
       this.loading = false;
       this.messageAlert = {viewMsg: true, msg: 'Error', type: 'danger'};
       this.cdr.markForCheck();
     })
   )
   .subscribe();
 }

 setPolicyDetails(policy) {
   this.registerForm.get('id').setValue(null);
   this.registerForm.get('policyNumber').setValue(policy.MainMember.Policy_Number);
   this.registerForm.get('idNumber').setValue(policy.MainMember.ID_Number);
   this.policyNumberReadonly = true;
   this.idNumberReadonly = true;
   this.registerForm.get('firstName').setValue(policy.MainMember.First_Name);
   this.registerForm.get('lastName').setValue(policy.MainMember.Surname);
   this.registerForm.get('firstName').setValue(policy.MainMember.First_Name);
   this.registerForm.get('lastName').setValue(policy.MainMember.Surname);
   this.registerForm.get('email').setValue(policy.MainMember.EmailAddress);
   this.registerForm.get('mobileNumber').setValue(policy.MainMember.Cell_Number);
   this.registerForm.get('password').setValue('');
   this.registerForm.get('confirmPassword').setValue('');
   this.registerForm.get('idNumberMismatch').setValue(this.idNumberMismatch);
   this.registerForm.get('clientInputIdNumber').setValue(this.clientInputIdNumber);
   this.registerForm.get('gender').setValue(policy.MainMember.Gender === '' ? 'OTHER' : policy.MainMember.Gender.toUpperCase());
   this.registerForm.get('physicalAddress').setValue(policy.MainMember.Address);
   this.registerForm.get('postalAddress').setValue(policy.MainMember.PostalAddress);
   let maritalStatus = policy.MainMember.Marital_Status === null ? 'NOT_AVAILABLE' : policy.MainMember.Marital_Status === '' ?  'NOT_AVAILABLE' : policy.MainMember.Marital_Status.toUpperCase()
   this.registerForm.get('maritalStatus').setValue(maritalStatus);
   this.registerForm.get('dateOfBirth').setValue(policy.MainMember.Date_Of_Birth);
   this.registerForm.get('title').setValue(policy.MainMember.Title === '' ? 'NOT_AVAILABLE' : policy.MainMember.Title.toUpperCase());
   this.registerForm.get('passportNumber').setValue(policy.MainMember.Passport_Number);
   this.registerForm.get('nyaradzoFranchise').setValue('SA');
 }

 /**
  * Checking control validation
  *
  * @param controlName: string => Equals to formControlName
  * @param validationType: string => Equals to valitors name
  */
 isControlHasError(controlName: string, validationType: string): boolean {
   const control = this.registerForm.controls[controlName];
   if (!control) {
     return false;
   }

   const result = control.hasError(validationType) && (control.dirty || control.touched);
   return result;
 }

}
