import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

import { DashboardService } from '../../services';
import { Router } from '@angular/router';
import { CrudService } from 'src/app/core/crud.service';
import { AuthStorage } from 'src/app/core/auth.storage';
import { PaymentHistoryComponent } from 'src/app/shared/shared-components/payment-history/payment-history.component';


@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent {

  links = {sip: '/get_covered/sip', scorepack: '/get_covered/local/sp/SCOREPACK', sixpack: '/get_covered/local/SIXPACK'};

  policyBalance: any;
  customer: any;
  policyNumber: string;
  idNumber: string;
  activePolicy: string;
  loading = false;
  @ViewChild(PaymentHistoryComponent)
  private paymentHistory: PaymentHistoryComponent;
  constructor( private router: Router, private service: CrudService, private cdr: ChangeDetectorRef, 	private authStorage: AuthStorage) {
      this.getCustomerDetails();
  }
  ngOnInit() {
    this.getPolicyPremium(this.policyNumber, this.idNumber);


  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.paymentHistory.getPayments(this.policyNumber);

  }
  navigate(link) {
		this.router.navigate([link]);
	}


  getPolicyPremium(policyNumber, idNumber) {
		if(policyNumber && idNumber){
      this.loading = true;
      this.activePolicy = policyNumber + ' [' + idNumber + ']';
		  this.service.getAny('/easipol/policy/premium-by-franchise?nyaradzoFranchise=SA&policyNumber=' + policyNumber).subscribe(
			result => {
			 this.policyBalance = result;
       this.loading = false;
			 this.cdr.markForCheck();
		},
    error =>{
      console.log(error);

      this.loading = false;
			 this.cdr.markForCheck();
    }
    );
	}
	}

  getCustomerDetails(){
	  this.customer = this.authStorage.getUser();
		this.policyNumber = this.customer!.policyNumber;
		this.idNumber = this.customer!.idNumber;
}
switchPolicies(item:any){
  this.activePolicy = item.policyNumber + ' [' + item.idNumber + ']';
  this.getPolicyPremium(item.policyNumber, item.idNumber);
  this.paymentHistory.getPayments(item.policyNumber);
  this.cdr.markForCheck();
}

}
