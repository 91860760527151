import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthStorage } from 'src/app/core/auth.storage';
import { CrudService } from 'src/app/core/crud.service';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnInit {
  page = 1;
  pageSize = 10;
  collectionSize = 0;
  payments: Array<any> = [];

  //receipts: Array<any> = [];
  isLoadingResults = false;
  policyBalance: any;
  customer: any;
  policyNumber: string;
  idNumber: string;

  isIEOrEdge: any;
  subgroupCurrency = null;
  currencyCode: string;
  nyaradzoFranchise: string;

  constructor(
	private cdr: ChangeDetectorRef,
	private authStorage: AuthStorage,
	private router: Router, private route: ActivatedRoute,
	private notification: ToastrService,
	private service: CrudService) {
		this.policyNumber =  this.route.snapshot.paramMap.get('policyNumber');
		this.idNumber =  this.route.snapshot.paramMap.get('idNumber');
		this.isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent)
	}


  ngOnInit() {
  // this.currencyCode = sessionStorage.getItem('CURRENCY_CODE');
  //  this.getCustomerDetails();
  //  this.getPayments();
  }


  getCustomerDetails(){
	  this.customer = this.authStorage.getUser();
	  if(this.policyNumber === null){
		this.policyNumber = this.customer!.policyNumber;
		this.idNumber = this.customer!.idNumber;
	}

  }
  get receipts(): any[] {
	  if(!this.payments){
		  return [];
	  }
    return this.payments
      .map((c, i) => ({id: i + 1, ...c}))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }
  getPayments(policyNumber){
	if(policyNumber ){
	this.isLoadingResults = true;
	this.service.getAny('/easipol/policy/receipt-history-by-franchise?nyaradzoFranchise=SA&policyNumber=' + policyNumber).subscribe(
		result =>{
			 this.payments = result!.ResponseObject;
			 this.collectionSize = this.payments.length;

			// this.getReceipts();

		},
		error =>{
			this.isLoadingResults = false;

		},
		() =>{
			this.isLoadingResults = false;

			this.cdr.markForCheck();
		}
	)
  }
  else{

	this.notification.error('No Policy Details Found', 'Incorrect Policy Details');
}
  }

	navigate(link){
		this.router.navigate([link]);
	}
}
