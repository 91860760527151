<div class="modal-header">
	<h5 class="modal-title">Create account to apply</h5>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<ngx-loading [show]="ngxLoading" [config]="{ backdropBorderRadius: '3px' }" ></ngx-loading>
	<form class="kt-form" [formGroup]="form" autocomplete="off">
		<div class="row">
			<div class="col-md-6">
				<div class="form-group">
					<mat-form-field appearance="fill" class="full-width">
						<mat-label>Firstname</mat-label>
						<input matInput type="text" placeholder="Firstname" formControlName="firstName"/>
					</mat-form-field>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group">
					<mat-form-field appearance="fill" class="full-width">
						<mat-label>Lastname</mat-label>
						<input matInput type="text" placeholder="Lastname" formControlName="lastName"/>
					</mat-form-field>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group">
					<mat-form-field appearance="fill" class="full-width">
						<mat-label>Phone Number</mat-label>
						<input matInput type="number" placeholder="Phone Number" formControlName="mobileNumber"/>
					</mat-form-field>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group">
					<mat-form-field appearance="fill" class="full-width">
						<mat-label>Email</mat-label>
						<input matInput type="email" placeholder="Email" formControlName="email" />
						<mat-error *ngIf="isControlHasError('email','required')">
							<strong>Email is required</strong>
						</mat-error>
						<mat-error *ngIf="isControlHasError('email','email')">
							<strong>Invalid email</strong>
						</mat-error>
						<mat-error *ngIf="isControlHasError('email','minlength')">
							<strong>Email length is too short</strong>
						</mat-error>
						<mat-error *ngIf="isControlHasError('email','maxlength')">
							<strong>Email length is too long</strong>
						</mat-error>
					</mat-form-field>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group">

					<mat-form-field appearance="fill" class="full-width">
						<mat-label>Enter Password</mat-label>
						<input matInput type="password" placeholder="New Password" formControlName="password"
							autocomplete="off" />
						<mat-error *ngIf="isControlHasError('password','required')">
							<strong>Password required</strong>
						</mat-error>
						<mat-error *ngIf="isControlHasError('password','minlength')">
							<strong>Password should have more than 5 letters</strong>
						</mat-error>
						<mat-error *ngIf="isControlHasError('password','maxlength')">
							<strong>Password should not not exceed more than 100 letters</strong>
						</mat-error>
					</mat-form-field>

				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group">
					<mat-form-field appearance="fill" class="full-width">
						<mat-label>Confirm password</mat-label>
						<input matInput type="password" placeholder="Confirm password" formControlName="confirmPassword"
							autocomplete="off" />
						<mat-error *ngIf="isControlHasError('confirmPassword','required')">
							<strong>Confirm password required</strong>
						</mat-error>
						<mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
							<strong>Password should have more than 5 letters</strong>
						</mat-error>
						<mat-error *ngIf="isControlHasError('confirmPassword','maxlength')">
							<strong>Password should not not exceed more than 100 letters</strong>
						</mat-error>
						<mat-error
							*ngIf="form.get('confirmPassword').errors && form.get('confirmPassword').errors.ConfirmPassword">
							<strong>Passsword and Confirm Password didn't match.</strong>
						</mat-error>
					</mat-form-field>
				</div>
			</div>
		</div>

	</form>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-primary" (click)="submitForm()">Save</button>
	<button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>
