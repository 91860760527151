
import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PasswordComponent } from '../password/password.component';
import { ToastrService } from 'ngx-toastr';
import { DownloadFormDialogComponent } from '../download-form-dialog/download-form-dialog.component';
import { CrudService } from 'src/app/core/crud.service';
import { AuthStorage } from 'src/app/core/auth.storage';
import { MatDialog } from '@angular/material/dialog';
import { LocalQuoteSummary } from 'src/app/core/model/local.quote.summary';

@Component({
  selector: 'kt-local-quotes',
  templateUrl: './local-quotes.component.html',
  styleUrls: ['./local-quotes.component.scss']
})
export class LocalQuotesComponent implements OnInit {
	 subgroup: string;
	customer: any;
  form: FormGroup;
  @Input() initValues: any;
	@ViewChild('wizard', {static: true}) el: ElementRef;
	mainMemberGroup: FormGroup;


	membersGroup: FormGroup;
	mainMemberProduct: any;
	extendedMemberSaProduct: any;
  extendedMemberZimProduct: any;
	mainMemberPremium = 0;
	extendedMemberSaPremium = 0;
  extendedMemberZimPremium = 0;
	dependentsSaCount: Array<any> = [];
  dependentsZimCount: Array<any> = [];
	supplimentaryBenefits: Array<any> = [];
	readonlyForProduct = false;

	planType: any;
	productType: any;
	dependentsSubPlanType: any;
	planTypeDependents: any;
	planTypesForDependents : Array<any> = [];
	planTypes: any[] = [
    {name: 'PREMIER', position: 4}, {name: 'PLATINUM', position: 3}, {name: 'DIAMOND', position: 2}, {name: 'GOLD', position: 1}
	];
	totalPremium = 0;
	mainMemberBenefitsAmt = 0;
	extendedMemberBenefitsAmt = 0;
	numOfZimDependents = 0;
  numOfSaDependents = 0;
	mainMemberBenefits?: Array<any> = [];
	extendedMemberBenefits?: Array<any> = [];
	disableMMBenefits = false;
	mainMemberBenefit = {supplimentaryID: 0};
  isProductSelected = false;

  productTypes: any[] = [];
  constructor(private fb: FormBuilder, private service: CrudService, private cdr: ChangeDetectorRef, private modalService: NgbModal,
	 private authStorage: AuthStorage, private router: Router, private notification: ToastrService, public dialog: MatDialog) {
	  this.customer = this.authStorage.getUser();
   }

  ngOnInit() {
	this.createForm();
  this.getProductType();
 }
  ngAfterViewInit(): void {
    const controls = this.form['controls'].mainMember['controls'];
  if(!this.mainMember.valid){
    Object.keys(controls).forEach(controlName =>
    controls[controlName].markAsTouched());

  }
    this.cdr.detectChanges();
  //  if(this.subgroup === 'SIXPACK'){
	//    this.numOfDependents = 5;
	//    this.readonlyForProduct = true;
	//    this.dependentsCount = Array(4).fill(0).map((x,i)=>i + 2);

  //  }
    if (this.initValues !== undefined) {
      this.form.get('mainMember.firstName').setValue(this.initValues.firstName);
      this.form.get('mainMember.lastName').setValue(this.initValues.lastName);
      this.form.get('mainMember.mobileNumber').setValue(this.initValues.phoneNumber);
      this.form.get('mainMember.email').setValue(this.initValues.email);
    }
}
 createForm() {
   this.form = this.fb.group({
	   id: new FormControl(),
	   version: new FormControl(),
	   createdById: new FormControl(),
	   dateCreated: new FormControl(new Date()),
	   mainMember: this.createMainMember(),

   });
}
getProductType() {
  this.service.getListAppendHeaders('/product/sub/product-type/get-all', 'authKey', '5eb924rt224iu5161eyyetgfyrA.ae527734987c301d599527798').subscribe(
    result => {
      this.productTypes = result;
    },
    error => {
      // this.loading = false
      // this.toastrService.danger('Error Encountered', error.message)
    },
  )
}
// downloadForm(){
// 	let downloadData = undefined;
// 	if(this.subgroup === 'SCOREPACK'){
// 		downloadData = {link: './assets/media/pdfs/Score-Pack-Plan01.pdf', title: 'SCORE PACK FORM'}
// 	} else {
// 		downloadData = {link: './assets/media/pdfs/Six-Pack-Plan01.pdf', title: 'SIX PACK FORM'}
// 	}

//  const dialogRef = this.dialog.open(DownloadFormDialogComponent, {
// 	 data: downloadData
// 	});

//  dialogRef.afterClosed().subscribe(result => {
// 	  console.log('The dialog was closed');

// 	});
//   }

createMainMember() {
	this.mainMemberGroup = new FormGroup({
		id: new FormControl(this.customer !== null ? this.customer!.id : null) ,
		version: new FormControl(this.customer !== null ?  this.customer!.version : null),
		createdBy: new FormControl(this.customer !== null ?  this.customer!.createdBy : null),
		dateCreated: new FormControl(this.customer !== null ?  this.customer!.dateCreated : null),
		title: new FormControl(this.customer !== null ?  this.customer!.title : null),
		firstName: new FormControl(
			this.customer !== null ?  this.customer!.firstName : null,
			Validators.compose([
				Validators.maxLength(25),
				Validators.minLength(2),
				Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
				Validators.required
			])
		),
		lastName: new FormControl(
			this.customer !== null ?  this.customer!.lastName : null,
			Validators.compose([
				Validators.maxLength(25),
				Validators.minLength(2),
				Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
				Validators.required
			])
		),
		email: new FormControl(this.customer !== null ?  this.customer!.email : null),
		province: new FormControl(this.customer !== null ?  this.customer!.province : null),
		street: new FormControl(this.customer !== null ?  this.customer!.street : null),
		gender: new FormControl(this.customer !== null ?  this.customer!.gender : null, Validators.compose([Validators.required])),
		mobileNumber: new FormControl(this.customer !== null ?  this.customer!.mobileNumber : null),
		postalAddress: new FormControl(this.customer !== null ?  this.customer!.postalAddress : null),
		customerId: new FormControl(this.customer !== null ?  this.customer!.customerId : null),
		physicalAddress: new FormControl(this.customer !== null ?  this.customer!.physicalAddress : null),
		city: new FormControl(this.customer !== null ?  this.customer!.city : null),
    country: new FormControl(this.customer !== null ?  this.customer!.country : null,Validators.compose([Validators.required])),
		policyNumber: new FormControl(this.customer !== null ?  this.customer!.policyNumber : null),
		memberType: new FormControl('MAIN_MEMBER'),
	});
	return this.mainMemberGroup;
}


get mainMember() { return this.form.get('mainMember'); }
onSubmit(){
		let quoteSummary = new LocalQuoteSummary() ;


	  quoteSummary.customer = this.form.get('mainMember').value;
	  quoteSummary.mainMemberProduct = this.mainMemberProduct;
	  quoteSummary.customerId = this.customer === null ? null : this.customer.customerId;
	  quoteSummary.extendedMemberProduct = this.extendedMemberSaProduct;
    quoteSummary.extendedMemberMarkedForZimProduct = this.extendedMemberZimProduct;

    //fixing new product object
    quoteSummary!.mainMemberProduct!.productType = null;
    quoteSummary!.mainMemberProduct!.subPlanType = null;
    quoteSummary!.mainMemberProduct!.planType = null;
    quoteSummary!.mainMemberProduct!.subGroup = null;

    if (!quoteSummary.extendedMemberProduct) {
      quoteSummary.extendedMemberProduct = {};
    }
      quoteSummary.extendedMemberProduct!.productType = null;
      quoteSummary!.extendedMemberProduct!.subPlanType = null;
      quoteSummary!.extendedMemberProduct!.planType = null;
      quoteSummary!.extendedMemberProduct!.subGroup = null;
      if (!quoteSummary.extendedMemberMarkedForZimProduct) {
        quoteSummary.extendedMemberMarkedForZimProduct = {};
      }
      quoteSummary!.extendedMemberMarkedForZimProduct!.productType = null;
      quoteSummary!.extendedMemberMarkedForZimProduct!.subPlanType = null;
      quoteSummary!.extendedMemberMarkedForZimProduct!.planType = null;
      quoteSummary!.extendedMemberMarkedForZimProduct!.subGroup = null;



    //end fix

	  quoteSummary.mainMemberBenefits = this.mainMemberBenefits;
	  quoteSummary.extendedMemberBenefits = this.extendedMemberBenefits;
	  quoteSummary.b45664925 = this.mainMemberBenefitsAmt;
	  quoteSummary.c4566446 = this.extendedMemberBenefitsAmt;
	  quoteSummary.r45636250 = this.totalPremium;
	  quoteSummary.numOfDependents = this.numOfSaDependents;
    quoteSummary.numOfZimMarkedDependents = this.numOfZimDependents;
  quoteSummary.subGroupName = 'SIP SOUTH AFRICA GROUP SCHEME';
  quoteSummary.subGroupId = 4233;
    quoteSummary.nyaradzoFranchise = "SA";
	  quoteSummary.t45663625 = this.extendedMemberSaPremium + this.extendedMemberZimPremium;
	  if(this.customer === null){
		this.openDialog(quoteSummary);
	} else {

	  this.service.save(quoteSummary, '/easipol/quote-application/save').subscribe(
		  result => {
			   this.router.navigate(['get_covered/members/' + false + '/' + result.id]);
			// this.router.navigate(['get_covered/recent_quotes']);
		  },
		  error => {
			console.log(error);
		  }
	  );
	}

}
openDialog(data) {
	const modalRef = this.modalService.open(PasswordComponent,  { size: 'lg', centered: true });
	modalRef.componentInstance.data = data;
	modalRef.componentInstance.saveUrl = '/easipol/quote-application/save';
	modalRef.componentInstance.isSip = false;
	modalRef.componentInstance.summary.subscribe((result) => {
		if(result !== undefined){
			 this.cdr.markForCheck();
		}
	})
}
onChangePlanTypes(memberType: string, isMarkedForZimDependent: boolean){
	this.planTypesForDependents = this.planTypes.filter(elem => elem.position <= this.planType.position);
	//this.planTypeDependents = this.planType;
	//this.dependentsSubPlanType = this.subPlanType;


	if (this.planType !== undefined && this.productType !== undefined) {
    this.isProductSelected = true;//4233
    const link = '/product/sub/auth/get-by-subgroup-and-types?subgroupid=4233&productType=' + this.productType + '&planType=' + this.planType.name  + '&memberType=' + memberType + '&markedForZimDependent=' + isMarkedForZimDependent;
		this.service.getAnyAppendHeaders(link, 'authKey', '5eb924rt224iu5161eyyetgfyrA.ae527734987c301d599527798').subscribe(
			result => {
        if(result != null){
          this.mainMemberProduct = result;
          this.mainMemberPremium = this.mainMemberProduct.premium;
          this.calculateTotalPremium(this.mainMemberPremium, this.mainMemberBenefitsAmt,  this.extendedMemberSaPremium, this.extendedMemberZimPremium, this.extendedMemberBenefitsAmt);
        }
			},
			error => {
				console.log(error);
			},
			() => {
        this.getSupplimentaryBenefits();
			  this.cdr.markForCheck();
			}
		);
	} else{
    this.isProductSelected = false;
  }
}
planTypeDependentsChange(){
	if (this.planType !== undefined) {

    const zimLink = '/product/sub/auth/get-by-subgroup-and-types?subgroupid=4233&productType=' + this.productType + '&planType=' + this.planTypeDependents.name  + '&memberType=EXTENDED&markedForZimDependent=true&nyaradzoFranchise=SA';
    const saLink = '/product/sub/auth/get-by-subgroup-and-types?subgroupid=4233&productType=' + this.productType + '&planType=' + this.planTypeDependents.name  + '&memberType=EXTENDED&markedForZimDependent=false&nyaradzoFranchise=SA';
    this.getDependentsProduct(zimLink, true);
    this.getDependentsProduct(saLink, false);
	} else if(this.numOfSaDependents === 0){
		this.mainMemberPremium = this.mainMemberProduct.premium;
		this.extendedMemberSaPremium = 0;
		this.calculateTotalPremium(this.mainMemberPremium, this.mainMemberBenefitsAmt,  this.extendedMemberSaPremium, this.extendedMemberZimPremium,  this.extendedMemberBenefitsAmt);
	}
  else if(this.numOfZimDependents === 0){
		this.mainMemberPremium = this.mainMemberProduct.premium;
		this.extendedMemberZimPremium = 0;
		this.calculateTotalPremium(this.mainMemberPremium, this.mainMemberBenefitsAmt,  this.extendedMemberSaPremium, this.extendedMemberZimPremium,  this.extendedMemberBenefitsAmt);
	}
}
numOfDependentsChange(isMarkedForZimDependent: boolean){
	if(this.numOfSaDependents === null){
		this.numOfSaDependents = 0;
	}
  if(this.numOfZimDependents === null){
		this.numOfZimDependents = 0;
	}
	this.dependentsSaCount = Array(this.numOfSaDependents).fill(0).map((x,i)=>i + 1);
  this.dependentsZimCount = Array(this.numOfZimDependents).fill(0).map((x,i)=>i + 1);
	this.planTypeDependentsChange();
}

getDependentsProduct(link:string, markedForZimDependent: boolean){
    this.service.getListAppendHeaders(link, 'authKey', '5eb924rt224iu5161eyyetgfyrA.ae527734987c301d599527798').subscribe(
      result => {
        console.log(result);

      if(markedForZimDependent){
        this.extendedMemberZimProduct = result;
        this.extendedMemberZimPremium = this.numOfZimDependents * this.extendedMemberZimProduct!.premium;
        this.calculateTotalPremium(this.mainMemberPremium, this.mainMemberBenefitsAmt,  this.extendedMemberSaPremium,  this.extendedMemberZimPremium, this.extendedMemberBenefitsAmt);
      } else{
        this.extendedMemberSaProduct = result;
        this.extendedMemberSaPremium = this.numOfSaDependents * this.extendedMemberSaProduct.premium;
        this.calculateTotalPremium(this.mainMemberPremium, this.mainMemberBenefitsAmt,  this.extendedMemberSaPremium,  this.extendedMemberZimPremium, this.extendedMemberBenefitsAmt);
      }
    },
    error => {
      console.log(error);
    },
    () => {
      this.cdr.markForCheck();
    }
  );
}

getSupplimentaryBenefits(){
	this.service.getListAppendHeaders('/product/sub/get-benefits-by-product-type?subGroupId=4233&productType=' +  this.productType, 'authKey', '5eb924rt224iu5161eyyetgfyrA.ae527734987c301d599527798').subscribe(
		result => {
     // console.log(result);
			this.supplimentaryBenefits = result;
		},
		error => {
			console.log(error);
		},
		() => {
		  this.cdr.markForCheck();
		}
	);
}
onChangeMainBenefits(value, isChecked) {

		if(this.mainMemberBenefit.supplimentaryID === value.supplimentaryID && !isChecked){
            this.disableMMBenefits = false;
		} else{
			this.disableMMBenefits = true;
		}
	if (isChecked) {
		this.mainMemberBenefitsAmt  = this.mainMemberBenefitsAmt + value.premium;
		this.mainMemberBenefit = value;
		this.disableMMBenefits = true;
		this.mainMemberBenefits.push(value);
	} else {
		this.mainMemberBenefitsAmt  = this.mainMemberBenefitsAmt - value.premium;
	    this.mainMemberBenefits = this.mainMemberBenefits.filter(item => item.supplimentaryID !== value.supplimentaryID);
	}
	this.calculateTotalPremium(this.mainMemberPremium, this.mainMemberBenefitsAmt, this.extendedMemberSaPremium, this.extendedMemberZimPremium, this.extendedMemberBenefitsAmt);
}
onChangeExtendedBenefits(value, isChecked){
	const premium = (this.numOfSaDependents + this.numOfZimDependents) * value.premium;
	if (isChecked) {
		this.extendedMemberBenefitsAmt  = this.extendedMemberBenefitsAmt + premium;
		this.extendedMemberBenefits.push(value);
	} else {
		this.extendedMemberBenefitsAmt  = this.extendedMemberBenefitsAmt - premium;
	 this.extendedMemberBenefits = this.extendedMemberBenefits.filter(item => item.supplimentaryID !== value.supplimentaryID);
	}
	this.extendedMemberBenefitsAmt = Number(this.extendedMemberBenefitsAmt.toFixed(2));
	this.calculateTotalPremium(this.mainMemberPremium, this.mainMemberBenefitsAmt, this.extendedMemberSaPremium, this.extendedMemberZimPremium, this.extendedMemberBenefitsAmt);
}

calculateTotalPremium(mmPremium: number, mmBenefitsAmt: number, extendedMemberPremiumSa: number, extendedMemberPremiumZim: number, extendedBenefitMemberAmt: number){
	this.totalPremium = mmPremium + mmBenefitsAmt + extendedMemberPremiumSa + extendedMemberPremiumZim + extendedBenefitMemberAmt;
	this.totalPremium = Number(this.totalPremium.toFixed(2));
}

}
