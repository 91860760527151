<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Date</th>
        <!-- <th scope="col">Receipt Number</th> -->
        <!-- <th scope="col">Remarks</th> -->
        <th scope="col">Payment Method</th>
        <!-- <th scope="col">Advance Payments</th>
        <th scope="col">Months For</th> -->
        <th scope="col">Receipt Amount</th>
        <th scope="col">Amount Due</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of receipts; let i = index">
        <th scope="row">{{ i + 1 }}</th>
        <td>{{item?.receiptDate | date: 'short'}}</td>
        <!-- <td>{{item?.receiptNumber}}</td> -->
        <!-- <td>{{ item?.remarks }}</td> -->
        <td>{{item?.paymentMethod}}</td>
        <!-- <td>{{ item?.monthsPaidInAdvance }}</td>
        <td>{{item?.monthsFor}}</td> -->
        <td>{{currencyCode}} {{ item?.receiptAmount }}</td>
        <td>{{currencyCode}} {{ item?.amountDue }}</td>
      </tr>
    </tbody>
  </table>
  </div>

  <div class="d-flex justify-content-between p-2">
  <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize">
  </ngb-pagination>

  <select class="custom-select" style="width: auto" [(ngModel)]="pageSize">
    <option [ngValue]="5">5 items per page</option>
    <option [ngValue]="10">10 items per page</option>
    <option [ngValue]="15">15 items per page</option>
  </select>
  </div>
