import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'kt-add-member-dialog',
  templateUrl: './add-member-dialog.component.html',
  styleUrls: ['./add-member-dialog.component.scss']
})
export class AddMemberDialogComponent implements OnInit {
  @Input() data;
  @Input() memberType;
  @Output() memberData: EventEmitter<any> = new EventEmitter();
  form: FormGroup;
  title = '';
   dateNow = new Date();
   minDate = new Date();
  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private cdr: ChangeDetectorRef) {

   }

  ngOnInit() {
	  if(this.memberType === 'C'){
		  this.title = 'CHILD DETAILS';
		  this.minDate = moment(this.dateNow).subtract(23, 'year').toDate();
	  } else if(this.memberType === 'E'){
		this.title = 'EXTENDED MEMBER DETAILS';
		this.minDate = moment(this.dateNow).subtract(100, 'year').toDate();
	  } else {
		this.title = 'BENEFICIARY DETAILS';
		this.minDate = moment(this.dateNow).subtract(100, 'year').toDate();
	  }
	  this.cdr.markForCheck();
	  this.createForm();
  }

  submitForm() {
	const controls = this.form.controls;
	if(!this.form.valid){
		Object.keys(controls).forEach(controlName =>
		controls[controlName].markAsTouched());
		return;
	 }
	  this.memberData.emit(this.form.value);
	  this.activeModal.close();
	}
	dateChange(value){
	const date = moment(value);
	const toDay= moment();
	const diff =toDay.diff(date, 'years');
     this.form.get('age').setValue(diff, 'years');
	}
	relationshipChange(value: string){
		if(value.includes('DAUGHTER')){
			this.form.get('gender').setValue('FEMALE');
		} else if(value.includes('SON')){
			this.form.get('gender').setValue('MALE');
		}else if(value.includes('MOTHER')){
			this.form.get('gender').setValue('FEMALE');
		}else if(value.includes('FATHER')){
			this.form.get('gender').setValue('MALE');
		}else if(value.includes('SISTER')){
			this.form.get('gender').setValue('FEMALE');
		}else if(value.includes('BROTHER')){
			this.form.get('gender').setValue('MALE');
		}else{
		switch(value){
			case 'NEPHEW' : {
				this.form.get('gender').setValue('MALE');
				break;
			}
			case 'NIECE' : {
				this.form.get('gender').setValue('FEMALE');
				break;
			}
			case 'UNCLE' : {
				this.form.get('gender').setValue('MALE');
				break;
			}
			case 'AUNT' : {
				this.form.get('gender').setValue('FEMALE');
				break;
			}
		}
	}
	}
  createForm() {
	if (this.data.firstName === undefined ) {
	this.form = this.fb.group({
		id: new FormControl(null),
		version: new FormControl(null),
		createdBy: new FormControl(null),
		dateCreated: new FormControl(null),
		title: new FormControl(null),
		position: new FormControl(this.data),
		firstName: new FormControl(
			null,
			Validators.compose([
				Validators.maxLength(25),
				Validators.minLength(2),
				Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
				Validators.required
			])
		),
		lastName: new FormControl(
			null,
			Validators.compose([
				Validators.maxLength(25),
				Validators.minLength(2),
				Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
				Validators.required
			])
		),
		idNumber: new FormControl(null, this.memberType==='C'?Validators.compose([]):Validators.compose([Validators.required])),
		memberType: new FormControl(this.memberType),
		dateOfBirth: new FormControl(null, Validators.compose([Validators.required])),
		gender: new FormControl(null, Validators.compose([Validators.required])),
		mobileNumber: new FormControl(null),
		email: new FormControl(null),
		relationship: new FormControl(null, Validators.compose([Validators.required])),
		passportNumber: new FormControl(null),
    age: new FormControl(null),
    isZimDependent: new FormControl('NO')

	});
} else {
	this.form = this.fb.group({
		id: new FormControl(this.data!.id),
		version: new FormControl(this.data!.version),
		createdBy: new FormControl(this.data!.createdBy),
		dateCreated: new FormControl(this.data!.dateCreated),
		title: new FormControl(this.data!.title),
		position: new FormControl(this.data!.position),
		firstName: new FormControl(
			this.data!.firstName,
			Validators.compose([
				Validators.maxLength(25),
				Validators.minLength(2),
				Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
				Validators.required
			])
		),
		lastName: new FormControl(
			this.data!.lastName,
			Validators.compose([
				Validators.maxLength(25),
				Validators.minLength(2),
				Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
				Validators.required
			])
		),
		idNumber: new FormControl(this.data!.idNumber, Validators.compose([Validators.required])),
		memberType: new FormControl(this.memberType),
		dateOfBirth: new FormControl(this.data!.dateOfBirth, Validators.compose([Validators.required])),
		gender: new FormControl(this.data!.gender, Validators.compose([Validators.required])),
		mobileNumber: new FormControl(this.data!.mobileNumber),
		email: new FormControl(this.data!.email),
		relationship: new FormControl(this.data!.relationship, Validators.compose([Validators.required])),
		passportNumber: new FormControl(this.data!.passportNumber),
    age: new FormControl(null),
    isZimDependent: new FormControl(this.data!.isZimDependent)

	});
}

}


}
