    <h3 class="text-center">RESET PASSWORD</h3>
		<ngx-loading [show]="ngxLoading" [config]="{ backdropBorderRadius: '3px' }" ></ngx-loading>
		<button routerLink="/auth/login" [disabled]="false"  class="btn btn-link"><i class="fa fa-arrow-left"></i></button>
    <ngb-alert *ngIf="messageAlert.viewMsg" [type]="messageAlert.type" [dismissible]="true">
      {{messageAlert.msg}}
    </ngb-alert>

		<form [formGroup]="form" autocomplete="off">
			<div class="form-group" *ngIf="tokenExists">
				<mat-form-field appearance="fill">
					<mat-label>Password</mat-label>
					<input matInput type="password" placeholder="New Password" formControlName="password" autocomplete="off"/>
					<mat-error *ngIf="isControlHasError('confirmPassword','required')">
            <strong>Password is required</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
            <strong>Length should be greater than  6</strong>
          </mat-error>
          <mat-error *ngIf="form.get('confirmPassword').errors && form.get('confirmPassword').errors.ConfirmPassword">
            <strong>Passsword and Confirm Password didn't match.</strong>
          </mat-error>
				</mat-form-field>
				<mat-form-field appearance="fill">
					<mat-label>Confirm Password</mat-label>
					<input matInput type="password" placeholder="Confirm Password" formControlName="confirmPassword" autocomplete="off"/>
          <mat-error *ngIf="isControlHasError('confirmPassword','required')">
            <strong>Confirm password is required</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
            <strong>Length should be greater than  6</strong>
          </mat-error>
          <mat-error *ngIf="form.get('confirmPassword').errors && form.get('confirmPassword').errors.ConfirmPassword">
            <strong>Passsword and Confirm Password didn't match.</strong>
          </mat-error>
				</mat-form-field>
			</div>
			<!--begin::Action-->
			<div class="confirm-btns">
				<button (click)="submit()" [disabled]="loading" id="kt_login_signin_submit" class="btn btn-primary btn-elevate confirm-btn" >Submit</button>
			</div>
			<!--end::Action-->
		</form>



